.form-control {
  font-size: .8rem;
}

.input-group-text {
  font-size: .8rem;
}

input.form-control.ng-invalid.ng-touched {
  border-color: #DC3545;
}

.form-field {
  display: block !important;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;

  .mat-form-field {
    width: 100%;

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }
  }

  .mat,
  .msg {

    &-error,
    &-hint,
    &.error {
      display: block;
      font-size: 12px;
      font-weight: 500;
      margin: 5px 0 0 5px;
    }

    &-error,
    &.error {
      color: var(--danger);
    }
  }
}

.form-field {
  display: block !important;
  width: 100%;
  text-align: left;
  margin-bottom: 25px;

  .mat-form-field {
    width: 100%;

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }
  }

  .msg {
    display: block;
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0 0 5px;

    &.error {
      color: var(--danger);
    }
  }
}

.mat-form-field.no-margin {
  .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
}
