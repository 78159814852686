.cdk-overlay-pane.progress-bar-modal {
  width: 100vw;
  height: 100vh;
  max-width: unset !important;
  z-index: 999999999999999999999999999999999;

  .mat-dialog-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .9);

    .progress-bar-text {
      text-align: center;
      color: #FFF;
      font-size: 22px;
    }

    .mat-progress-bar {
      max-width: 50vw;
      min-width: 300px;
      height: 5px;

      &-fill::after {
        background-color: var(--main);
      }
    }
  }
}
