select.form-control:not([size]):not([multiple]) {
  height: 33px;
}

.ng-select {
  &.ng-select-single {
    .ng-select-container {
      height: 33px;
    }
  }

  .ng-select-container {
    border-radius: 0;
    min-height: 33px;
  }

}

.custom-select {
  height: 33px;
}

.mat-option {
  &.full-option-view {
    height: unset !important;
    line-height: 1.8 !important;
    white-space: unset;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.checkbox-select {
  .mat-checkbox-layout {
    margin: 0 !important;
  }
}
