.shortDate {
  font-weight: 600 !important;

  &.error {
    display: flex;
    align-items: center;
    color: var(--red);

    &::before {
      content: '';
      display: block;
      width: 13px;
      height: 13px;
      margin: {
        right: 5px;
        left: 3px;
      };

      background: url(/assets/icons/warning.png) center center / 13px no-repeat;
    }
  }
}
