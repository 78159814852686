@import "node_modules/bootstrap/scss/bootstrap-grid";

.btn {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.xl {
    padding: 13px 32px;
    font-weight: bold;
  }

  &.sm {
    padding: 4px 12px;
  }

  .fa {
    margin-right: 6px;
  }

  &.remove.small {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 20px;
    width: 30px;
    height: 30px;
    border-radius: 100%;

    @include media-breakpoint-down(sm) {
      width: 20px;
      height: 20px;
      font-size: 16px;
    }
  }
}

.button-navigation,
.button-navigation-inline {
  flex-wrap: wrap;
}

.button-navigation {
  @include media-breakpoint-down(sm) {
    .btn {
      margin: 0 0 5px 0 !important;
      width: 100%;
    }
  }
}

.button-navigation-inline {
  @include media-breakpoint-down(sm) {
    display: flex;

    .btn {
      flex-grow: 1;
    }
  }
}

.form-group,
.d-sm-inline-block,
.massaction-dropdown-container {
  > * {
    + * {
      margin-left: 4px;
    }
  }
}

* > {
  .btn {
    + .btn {
      margin-left: 4px;
    }
  }
}

button[disabled] {
  cursor: default;
}
