.mat-form-field-autocomplete {
  width: 100%;
}

.mat-autocomplete-panel {
  .mat-option {
    font-size: inherit;
    line-height: 3em;
    height: 3em;
  }
}
