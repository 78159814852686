@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/bootstrap-grid';

.table-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

table {

  &.table-hover {
    tr {
      transition: .3s ease;
    }
  }

  &.table {
    margin-bottom: 0;

    thead {

      tr {

        th {
          background-color: var(--main);
          color: #FFF;
          white-space: nowrap;
          user-select: none;
          vertical-align: middle;

          &.actions {
            width: 1%;
            white-space: nowrap;
          }
        }
      }
    }

    tbody {

      tr {

        &.mark-row {
          background-color: var(--yellow) !important;
        }

        &.link {
          cursor: pointer;
        }

        &.warning {
          background-color: var(--warning);
        }

        &.danger {
          background-color: var(--danger);
        }

        &.danger-light {
          background-color: var(--danger-light);
        }

        &.product-not-exists {
          color: red;
        }

        &.whiteText {
          color: #FFF;
          border-bottom: 2px solid #FFF;

          .ng-star-inserted {
            border-color: #FFF;
          }
        }

        td {
          vertical-align: middle;

          &.comment {
            @include media-breakpoint-up(md) {
              max-width: 400px;
            }
          }

          &.link {
            cursor: pointer;
            color: var(--main);
          }

          &.product-img {
            width: 62px;
            min-width: 62px;

            img {
              max-width: 100%;
            }
          }

          &.actions {
            width: 1%;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.mobile-table-row {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  padding: 11px 15px;
  border-bottom: 1px solid var(--gray2);

  &.ornament {
    background-color: var(--gray1);
  }

  p, a {
    display: block;
    font-size: 13px;
    line-height: 1;
    margin-bottom: 5px;
  }

  .img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 65px;
    padding-right: 15px;

    .img {
      max-width: 48px;
      height: 100%;
      max-height: 48px;
    }
  }

  .content {
    flex-grow: 1;
    margin-right: 5px;

    .shortDate {
      margin-bottom: 5px;
    }

    .boxes-wrap {
      margin: 7px 0;

      .box {
        font-weight: 600;
        margin-bottom: 4px;
      }
    }
  }

  .left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    align-self: stretch;

    &.no-btn {
      position: relative;
      justify-content: center;

      .quantity {
        margin: 0;
      }

      .collapse-btn {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .quantity {
      margin-bottom: 10px;
    }

    .collapse-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 8px;
      margin-bottom: 15px;
      border: none;
      background-color: transparent;
      transition: transform .3s ease;

      &.active {
        transform: rotate(180deg);
      }
    }
  }
}


@include media-breakpoint-down(sm) {

  table {

    &.table:not(.no-responsive) {

      thead {

        tr {

          th {
            display: none;
          }
        }
      }

      tbody {

        .mat-expansion {

          &-indicator::after {
            margin-bottom: 5px;
          }

          &-panel {
            box-shadow: none;
            border-top: 1px solid #dee2e6;
            border-radius: 0;
            background: transparent;

            &-header {
              height: 45px;
              padding: 0 0.75rem;

              &-title {
                font-size: 0.8rem;
                font-weight: bold;
              }
            }

            &-body {
              padding: 0 0.75rem 0.75rem;
              font-size: 0.8rem;
            }
          }
        }

        tr {
          display: block;
          position: relative;

          td {
            display: flex;
            align-items: center;
            min-height: 45px;

            &::before {
              min-width: 100px;
              max-width: 100px;
              content: attr(data-th);
              font-weight: bold;
              margin-right: 10px;
            }
          }
        }
      }
    }

    &.no-label {

      tbody {

        tr {

          td {

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.table {

  &__details {

    &-row {
      padding: 0 !important;
    }
  }

  &__actions {

    &-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-indicator {
      cursor: pointer;
      overflow: visible !important;

      &.white {
        color: var(--white);
      }
    }
  }

  &__filters {

    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-input {
      width: 140px;
    }
  }

  &__filters .form-group > * + * {
    margin-left: 0;
  }
}

.table-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__indicator {
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .table-grid {
    display: block;
  }

  table {
    &.table:not(.no-responsive) thead tr {
      background-color: var(--main);
      overflow: auto;
      display: block;
      width: 100%;
      color: #fff;
      padding: 5px 0;
    }
  }
}
