@import 'node_modules/bootstrap/scss/bootstrap-grid';

.mat-dialog-container {
  position: relative;
  border-radius: 8px !important;

  .action-buttons-wrap {
    display: flex;
    flex-direction: column;

    .btn {
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modal-close-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -12px;
    right: -12px;
    width: 12px;
    height: 12px;
    opacity: 1;
    padding: 0;
    background-color: unset;
    border: none;
  }

  .text {
    font-size: 18px;
    font-weight: 500;
  }

  .content-wrapper {
    overflow: auto;
    max-height: 80vh;
    padding-top: 10px;

    @media screen and (max-width: 797px) {
      max-height: 65vh;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 0;
    border: none;

    .actions {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}

.mat-dialog-title {
  margin-bottom: 10px !important;

  & + .mat-dialog-content {
    padding-top: 10px;
  }
}

.mat-dialog-content {
  overflow: visible;
}

.mat-dialog-footer {
  padding-top: 20px;
}

.modal {

  .actions-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;

    .btn {
      margin-bottom: 10px;

      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }

  &.fade {

    .modal-dialog {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      margin: 0;

      .modal-content {
        .modal-header {
          padding: 8px 15px;
          border-radius: 0;
          background-color: var(--main);
          color: #FFF;

          .modal-title {
            display: inline-block;
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: bold;
          }

          button {

            &.close {
              opacity: .8;

              &:hover {
                opacity: 1;
              }

              span {
                color: #FFF;
              }
            }
          }
        }

        .modal-footer {
          background-color: #EFEFEF;
          padding: 5px 10px;
        }
      }
    }

    .modal-dialog.height-90 {
      max-height: 90%;
    }

    @include media-breakpoint-down(sm) {

      .modal-dialog {
        top: 50%;
        bottom: 2%;
        max-height: 96%;
        width: 96%;
        position: relative;
        transform: translate(-50%, -50%);

        .modal-content {
          height: 100%;
          position: relative;
          overflow: hidden;

          .modal-header {
            position: absolute;
            top: 0;
            width: 100%;
          }

          .modal-body {
            margin: 40px 0;
            overflow-y: scroll;
            height: calc(100% - 90px);
          }

          .modal-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
