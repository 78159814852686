.full-width-field {
  width: 100%;
}

.generic-icon {
  cursor: pointer;
}

.mat-autocomplete-panel {
  .mat-option {
    font-size: inherit;
    line-height: 3em;
    height: 3em;
  }
}
