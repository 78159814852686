@import 'variables.scss';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/ngx-toastr/toastr';
@import 'node_modules/font-awesome/scss/font-awesome';
@import 'accordion.scss';
@import 'checkboxes.scss';
@import 'toastr.scss';
@import 'select.scss';
@import 'shortDate.scss';
@import 'input.scss';
@import 'autocomplete.scss';
@import 'modal.scss';
@import 'progress-bar.scss';
@import 'table.scss';
@import 'button.scss';
@import 'form.scss';
@import 'tab.scss';
@import 'label.scss';
@import 'typography.scss';
@import 'material.scss';
@import 'helpers';
@import '~dragula/dist/dragula.css';
@import 'table-mobile.scss';

html {
  height: 100%;
  position: relative;
}

* {
  outline: 0 !important;
  font-family: 'Roboto', sans-serif;
}

body {
  height: 100%;
  background-color: #F4F4F4;
  font-size: .8rem;
  overflow: hidden;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
}

.c-pointer {
  cursor: pointer;
}

main {

  h1 {

    &.page-title {
      font-size: 1.1rem;
      color: #555;
      padding-bottom: 5px;
      margin: 10px 0;
      border-bottom: 1px solid #DDD;
    }
  }

  h2 {
    margin: 10px 0 15px 0;
    font-size: 1.2em;
    text-transform: uppercase;
    color: #555;
  }

  label {
    font-weight: bold;
  }

  ul.blocked-orders {
    padding-left: 20px;
    margin: 0;

    li {
      margin-bottom: 2px;
    }
  }

  table {

    &.product-list {

      td {
        vertical-align: baseline;

        ul.product-short-info {
          list-style: none;
          margin: 10px 0 0 0;
          padding: 0;

          li {
            margin-right: 5px;

            @include media-breakpoint-up(md) {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .mobile-overlay {
    display: none;
    padding: 0;

    @include media-breakpoint-down(sm) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

footer {

  &.app-footer {
    background-color: var(--main);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 15px;
    z-index: 1000;

    span {

      &.logout-link {
        color: #FFF;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// overriding globally angular material styles

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--main);
}

.mat-slide-toggle-content {
  font-weight: 500;
}

button[color = 'primary'].mat-raised-button {
  background: var(--main);
}

// overriding globally bootstrap styles

button.btn-primary,
.btn-primary {
  background-color: var(--main);
  border-color: var(--main);
}
