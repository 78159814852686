.table-mobile {
  .table-mobile-row {
    background-color: #fff;
    margin-bottom: 20px;
    padding: 10px;
    overflow: hidden;

    .row-title,
    .variant-title {
      color: var(--main);
      font-weight: bold;
    }

    .row-title {
      font-size: 14px;
      text-align: center;
      margin: 10px 15px 15px 15px;
    }

    .variant-title {
      font-size: 13px;
      padding: 0 16px;
    }

    .title-list {
      .list-item {
        font-weight: bold;
      }
    }

    .text-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .list-item {
      font-size: 13px;
      height: auto;
      margin-bottom: 10px;

      .mat-list-item-content,
      .mat-list-item-ripple {
        margin-bottom: 0;
      }
    }

    .heading-item {
      font-weight: bold;
    }

    div:not(.row) {
      margin-bottom: 10px;
    }
  }
}

.table-responsive {
  .table-label-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .table-label {
      margin: 0;
    }
  }

  .title-count {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
  }
}
