.mat-form-field {
  &.input-table {
    .mat-form-field-wrapper {
      padding: 0;
      margin-bottom: 0;
      background-color: #FFF;
    }
  }
}

.mat-option-auto-height {
  .mat-option {
    height: auto !important;
    line-height: 30px !important;
  }
}

