@import 'node_modules/bootstrap/scss/bootstrap-grid';

#toast-container {
  width: 400px;

  .toast {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
  }

  .toast-message {
    white-space: pre-line;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  #toast-container {
    width: auto;
    left: 12px;
    top: 75px;

    .ngx-toastr {
      width: 100%;
    }
    .toast-message {
      font-size: 20px;
    }
  }
}
