.table-label {
  font-size: 25px;
  color: var(--main);
  margin-bottom: 10px;
  text-transform: unset;
}

.table-count {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
