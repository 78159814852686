@import 'node_modules/bootstrap/scss/bootstrap-grid';

accordion {
  .panel {
    .panel-heading {
      background-color: var(--main);
      color: #fff;
      cursor: pointer;

      .panel-title {
        user-select: none;
        position: relative;

        &::after {
          position: absolute;
          right: 0;
          top: 0;
          content: '▼';
        }
      }
    }

    &.panel-open {
      .panel-heading {
        .panel-title {
          position: relative;

          &::after {
            content: '▲';
          }
        }
      }
    }
  }
}
