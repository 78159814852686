:root {
  // Colors
  --main: #005073;
  --second: #58A8FD;
  --dummy-bg: rgba(150, 141, 141, .2);
  --dummy-text: rgba(150, 141, 141, .6);
  --warning: rgba(231, 76, 60, .7);
  --warning-dark: rgb(235, 77, 75);
  --gray: #DEE2E6;
  --gray1: #EDEDED;
  --gray2: rgba(200, 201, 201, .6);
  --gray3: #E6EEF7;
  --gray4: rgba(0,0,0,.38);
  --blue: #005073;
  --blue2: #58A8FD;
  --yellow: #FFFEC4;
  --black: #000;
  --white: #fff;
  --red: #DC3545;
  --menu-hover: rgba(0, 0, 0, .04);
  --danger-light: #c9777f;
}
